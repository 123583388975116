import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Error from "./pages/error";
import About from "./pages/about";
import Contact from "./pages/contact";
// import "./App.css";

import ReactGA from "react-ga";
import { useEffect } from "react";
const TRACKING_ID = "UA-258749121-1";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/testimonies" element={<Testimonies />} /> */}
        <Route path="/contact" element={<Contact />} />

        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
