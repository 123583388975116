import React from "react";
import rol_white from "../assets/rol_white.png";

export default function Footer() {
  return (
    <div>
      {" "}
      {/* Footer */}
      <footer className=" bg-gray-700">
        <div className="mx-auto mb-12 flex flex-col-reverse justify-between gap-8 px-6 py-10 md:mb-0 md:flex-row md:justify-center md:gap-36">
          {/* logo and social links container */}
          <div className="flex flex-col-reverse items-center justify-between space-y-12 md:flex-col lg:my-auto">
            <img src={rol_white} alt="" className=" w-24 " loading="lazy" />
          </div>
          <div className="my-auto mx-auto text-white md:mx-0">
            <h1>River Of Life Worship Center</h1>
            <a
              href="https://maps.app.goo.gl/ZRhUN4rC5XxZSTd9A"
              className="underline"
            >
              1120 Attaway Ln <br /> Fouke, AR 71837 Arkansas
            </a>
            <div>riveroflifefouke@gmail.com | 430-200-7682</div>
          </div>
          {/* <div
            className="ml-form-embed embeddedForm"
            data-account="3392599:g3f2s7l6c2"
            data-form="5825078:n6n6j1"
          ></div> */}
        </div>
      </footer>
    </div>
  );
}
