import React from "react";
import { Helmet } from "react-helmet";

export default function Contact() {
  return (
    <div>
      <Helmet>
        <title>Contact | Pathway Resource Center</title>
        <meta
          name="description"
          content="Get in touch with a member of Pathway Resource Center for information about free pregnancy services, volunteering, or upcoming community events."
        />
      </Helmet>
      <section className="font-bonanova">
        <div className="mx-auto max-w-screen-md py-8 px-4 lg:py-16">
          <h2 className="mb-8 text-center text-4xl font-bold tracking-tight text-gray-700">
            Get In Touch
          </h2>
          <div className="ml-[18%] lg:ml-[25%] lg:text-2xl">
            <h1>River Of Life Worship Center</h1>
            <a
              href="https://maps.app.goo.gl/ZRhUN4rC5XxZSTd9A"
              className="underline"
            >
              1120 Attaway Ln <br /> Fouke, AR 71837 Arkansas
            </a>
            <div>riveroflifefouke@gmail.com | 430-200-7682</div>
          </div>
          <p className="my-10 text-center">The form below does not yet work</p>
          <form action="https://" method="POST" className="space-y-6">
            <div>
              <label
                for="email"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                placeholder="name@email.com"
                required
              />
            </div>
            <div>
              <label
                for="phone"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Phone number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                placeholder="123-456-7899"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                required
              />
            </div>
            <div>
              {/* <label
                for="subject"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Subject
              </label> */}
              {/* <input
                type="text"
                id="subject"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Let us know how we can help you"
                required
              /> */}
              {/* <select
                id="contact_reason"
                name="contact_reason"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              >
                <option selected className="text-xs">
                  Reason for Contact
                </option>
                <option value="pregnant" className="text-xs">
                  Pregnancy Services
                </option>
                <option value="volunteer" className="text-xs">
                  Volunteer
                </option>
                <option value="other" className="text-xs">
                  Other
                </option>
              </select> */}
            </div>
            <div className="sm:col-span-2">
              <label
                for="message"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Your message
              </label>
              <textarea
                id="message"
                name="message"
                rows="6"
                className="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                placeholder="Leave a comment..."
              ></textarea>
            </div>
            <button
              type="submit"
              className="hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 rounded-lg bg-gray-700 py-3 px-5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 sm:w-fit"
            >
              Send message
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}
