import React from "react";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <div className="font-bonanova md:mx-auto md:w-1/2 lg:min-h-[75vh]">
      <Helmet>
        <title>Who We Are | River Of Life</title>
        <meta
          name="description"
          content="Discover Pathway Resource Center's mission and goal for the community."
        />
      </Helmet>

      <h1 className="my-8 text-center text-3xl lg:mt-24">
        Doctrinal Affirmation
      </h1>
      <p className="mx-6 mb-6">
        We recognize that there is only one true and living God who exists as
        the Father, Son and Holy Spirit and that He is worthy of all honor,
        praise and glory as the Creator, Sustainer and Beginning and End of all
        things.
      </p>

      <p className="mx-6 mb-6">
        We recognize Jesus Christ as the Messiah, the Word who became flesh and
        dwelt among us. We believe that He came to destroy the works of Satan,
        that He disarmed the rulers and authorities and made a public display of
        them, having triumphed over them.
      </p>
      <p className="mx-6 mb-6">
        We believe that God has proven His love for us because when we were
        still sinners, Christ died for us. We believe that He delivered us from
        the domain of darkness and transferred us to His Kingdom, and in Him we
        have redemption, the forgiveness of sins. ​
      </p>
      <p className="mx-6 mb-6">
        We believe that we are children of God and that we are seated with
        Christ in the heavenlies. We believe that we are saved by the grace of
        God through faith, that it was a gift and not the result of any works on
        our part.
      </p>
      <p className="mx-6 mb-6">
        We choose to be strong in the Lord and in the strength of His might. We
        put no confidence in the flesh for the weapons of warfare are not of the
        flesh. We put on the whole armor of God and we resolve to stand firm in
        faith and resist the evil one.
      </p>
      <p className="mx-6 mb-6">
        We believe that apart from Christ we can do nothing, so we declare
        ourselves dependent on Him. We choose to abide in Christ in order to
        bear much fruit and glorify the Lord. We announce to Satan that Jesus is
        our Lord, and we reject any counterfeit gifts or works of Satan in our
        lives.
      </p>
      <p className="mx-6 mb-6">
        We believe that the truth will set us free and that walking in the light
        is the only path of fellowship. Therefore, we stand against Satan’s
        deception by taking every thought captive in obedience to Christ. We
        declare that the Bible is the only authoritative standard. We choose to
        speak the truth in love.
      </p>
      <p className="mx-6 mb-6">
        We chose to present our bodies as instruments of righteousness, a living
        and holy sacrifice; and we will renew our minds by the living Word of
        God in order that we may prove that the will of God is good, acceptable
        and perfect. We put off the old self with its evil practices and put on
        the new self, and we declare to be a new creature in Christ.
      </p>
      <p className="mx-6 mb-6">
        We ask our heavenly Father to fill us with his Holy Spirit, lead us into
        all truth, and empower our lives that we may live above sin and not
        carry out the desires of the flesh. We crucify the flesh and chose to
        walk by the Spirit.
      </p>
      <p className="mx-6 mb-6">
        We renounce all selfish goals and choose the ultimate goal of love. We
        choose to obey the two greatest commandments, to love the Lord our God
        with all our heart, soul and mind, and to love our neighbors as
        ourselves.
      </p>
      <p className="mx-6 mb-6">
        We believe that Jesus has all authority in Heaven and on Earth and that
        he is the head over all rule and authority. We believe that Satan and
        his demons are subject to us in Christ since we are members of Christ’s
        body. Therefore, we obey the command to submit to God and to resist the
        devil, and we command Satan in the name of Christ to leave our presence.
      </p>
    </div>
  );
}
