import React from "react";
// import logo from "../assets/rol.png";
import logo_name from "../assets/ROL_name.png";
import { SiFacebook } from "react-icons/si";

export default function Nav() {
  const handleHamburgerClick = () => {
    const btn = document.getElementById("menu-btn");
    // const nav = document.getElementById("menu");
    const mobileMenuWrapper = document.getElementById("mobile-menu-wrapper");

    btn.classList.toggle("open");
    // nav.classList.toggle("hidden");
    mobileMenuWrapper.classList.toggle("mobile-menu-open");
    mobileMenuWrapper.classList.toggle("mobile-menu-close");
  };

  return (
    <div className="fixed top-0 z-30 w-full bg-white font-bonanova shadow-md">
      <nav className="container relative mx-auto p-4">
        <div className="flex items-center justify-between">
          <div className="pt-2">
            <a href="/">
              <img src={logo_name} alt="" className="h-[55px]" loading="lazy" />
            </a>
          </div>
          {/* Hamburger */}
          <button
            id="menu-btn"
            className="hamburger mr-2 mt-2 block focus:outline-none md:hidden"
            onClick={handleHamburgerClick}
          >
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
          {/* Menu items */}
          <div className="hidden items-center gap-5 md:flex">
            <a href="/about">About</a>
            <a href="/contact">Connect</a>
            {/* <a href="/testimonies">Stories</a> */}

            <a
              href="https://donorbox.org/tithes-and-offerings-44"
              target="_blank"
              rel="noreferrer"
              className="rounded-md bg-[#336077] px-2 py-1 text-white"
            >
              Give
            </a>
            <a
              href="https://www.facebook.com/RiverofLifeWorshipCenterFouke/"
              target="_blank"
              rel="noreferrer"
              className="rounded-md px-1 py-1 text-3xl text-[#336077]"
            >
              <SiFacebook />
            </a>
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          className="mobile-menu-close w-screen md:hidden"
          id="mobile-menu-wrapper"
        >
          <div
            id="menu"
            className="fixed left-[-16px] mt-4 mr-2 flex w-screen flex-col gap-4 rounded-b-md bg-gray-600 py-6 px-3 text-center font-bold text-white"
          >
            <a
              href="https://donorbox.org/tithes-and-offerings-44"
              target="_blank"
              rel="noreferrer"
              className="m-auto  w-24 rounded-md bg-[#336077] px-2 py-1 text-center text-white"
            >
              Give
            </a>
            <a href="/about" className="text-sm font-normal">
              About
            </a>
            <a href="/contact" className="text-sm font-normal">
              Connect
            </a>

            <a
              href="https://www.facebook.com/RiverofLifeWorshipCenterFouke/"
              target="_blank"
              rel="noreferrer"
              className="mx-auto rounded-full bg-white text-4xl text-[#336077]"
            >
              <SiFacebook />
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}
