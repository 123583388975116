import React from "react";
import { Helmet } from "react-helmet";
// import GetInTouchBanner from "../components/getintouchbanner";
// import Services from "../components/services";
// import Location from "../components/location";
// import { AuctionModal } from "../components/auctionmodal";

// import pathway_plain from "../assets/pathway_plain.webp";

export default function Home() {
  return (
    // <div className="App bg-[#336077] font-bonanova">
    <div className="App bg-white font-bonanova">
      <Helmet>
        <link rel="icon" href="favicon.ico" />
      </Helmet>
      {/* <AuctionModal /> */}
      <div className="flex h-[70vh] w-screen flex-col justify-end bg-baptism_banner bg-cover bg-center lg:h-[85vh]">
        {/* <img
          src={pathway_plain}
          alt="path"
          className="object-contain mx-auto"
        /> */}
        <p className="  rounded-b-md px-8 pt-8 pb-12 text-5xl text-white lg:mx-auto lg:mb-10 lg:w-2/3 lg:text-8xl">
          Make <br />
          Jesus <br />
          Known
        </p>
      </div>

      <div className="mx-12 my-20 lg:mx-auto lg:w-2/3 lg:text-lg">
        <p className="text-center text-gray-700">
          We are a Biblical community based on the commands and commission of
          Christ. <br />
          <br />
          We strive to live out the commands and commission of Christ. This
          means that first and foremost we love the Lord our God with all of our
          heart, with all our soul, and with all our mind. Not only do we love
          God, we each seek to love our neighbor as ourself.
          <br />
          <br />
          This also means that we make disciples who make disciples, baptizing
          them in the name of the Father and of the Son and of the Holy Spirit,
          teaching them to observe everything Christ has commanded us.
        </p>
      </div>

      <div className="  bg-church_overview bg-cover bg-center py-32 lg:py-48">
        <div className=" mx-auto w-3/5  rounded-md bg-gray-500 bg-opacity-80 py-2 text-lg font-semibold lg:w-1/3 lg:text-2xl lg:font-medium">
          <p className="whitespace-nowrap text-center text-white">
            <span className="underline">Sunday Service</span> <br />
            Coffee & Donuts - 9:30AM <br /> Service - 10AM
          </p>
          <br />
          <p className="whitespace-nowrap text-center text-white">
            <span className="underline">Wednesday Service</span> <br />
            Children & Youth - 6PM <br /> (Meal Provided)
            <br />
            Young Adult - 8PM
          </p>
        </div>
      </div>
    </div>
  );
}
